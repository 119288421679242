import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TemplateRef } from "@angular/core";
import { SignUpDrawerComponent } from "../sign-up-drawer/sign-up-drawer.component";
import { NgIf } from "@angular/common";

declare var jQuery: any;

@Component({
    selector: "app-navigation",
    templateUrl: "./navigation.component.html",
    styleUrls: ["../landing-page.component.scss"],
    imports: [
        NgIf,
        RouterLink,
        SignUpDrawerComponent,
    ]
})
export class NavigationComponent implements OnInit {
  public email: String = "";
  public data: any = this.localStorage.get("data");
  public modalRef: BsModalRef;
  public signUpDrawerOpen: Boolean = false;

  constructor(
    public localStorage: LocalStorageService,
    public router: Router,
    public modalService: BsModalService
  ) {}

  ngOnInit(): void {}

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }

  ngAfterViewInit(): void {
    // Fixed navigation when scrolling
    jQuery(window).on("scroll", function () {
      var scrollPosition = jQuery(this).scrollTop();
      scrollPosition > 0
        ? jQuery(".top-bar").addClass("fixed")
        : jQuery(".top-bar").removeClass("fixed");
    });

    // Mobile menu and fixed menu when page load scrolled
    jQuery(document).ready(function () {
      jQuery(window).scrollTop() > 0
        ? jQuery(".top-bar").addClass("fixed")
        : jQuery(".top-bar").removeClass("fixed");

      jQuery(".menu-icon").click(function () {
        if (jQuery(".nav-list").hasClass("open")) {
          jQuery(".nav-list").removeClass("open");
        } else {
          jQuery(".nav-list").addClass("open");
        }
      });

      jQuery(".nav-list li a").click(function () {
        jQuery(".nav-list").removeClass("open");
      });
    });
  }

  startRegistration(): void {
    // this.localStorage.set("registration_email", this.email);
    // this.router.navigate(["/registration/join"]);
    this.signUpDrawerToggle(true);
  }

  signUpDrawerToggle(x): void {
    this.signUpDrawerOpen = x;
  }
}
