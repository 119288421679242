import { Component, OnInit } from '@angular/core';
import { SignUpDrawerComponent } from '../../sign-up-drawer/sign-up-drawer.component';

@Component({
    selector: 'app-schedule-demo',
    templateUrl: './schedule-demo.component.html',
    styleUrls: ['./schedule-demo.component.css'],
    imports: [SignUpDrawerComponent]
})
export class ScheduleDemoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
