<header class="top-bar" role="banner">
  <div class="container">
      <nav role="navigation">
          <div class="row d-flex align-items-center">
              <div class="col-lg-3">
                  <div class="logo-wrapper">
                      <a class="logo link" href="#/"><img src="assets/img/logo-default.svg"
                              alt="Client Hub Logo"></a>
                      <i class="visible-xs visible-sm visible-md feather menu menu-icon"></i>
                  </div>
              </div>
              <div class="col-lg-6">
                  <ul class="nav-list text-center">
                      <li>
                          <a class="link" href="#/">Home</a>
                      </li>

                      <li>
                          <a class="link link-scroll" href="#/#pricing">Pricing</a>
                      </li>

                      <li>
                          <a class="link" href="#/about-us">About Us</a>
                      </li>
                      <li dropdown placement="bottom right"
                              style="position: relative; font-size: 1rem; color: #0075FF; cursor: pointer">
                              <div dropdownToggle>
                                  View Demo
                                  <i class="feather chevron-down"></i>
                              </div>
                              <ul *dropdownMenu class="dropdown-menu" role="menu">
                                  <li role="menuitem">
                                      <a (click)="openModal(demoModal)">Watch it now</a>
                                  </li>
                                  <li role="menuitem">
                                      <a (click)="openModal(demoScheduleModal)">Schedule meeting</a>
                                  </li>
                              </ul>
                          </li>

                      <li class="hidden visible-xs visible-sm visible-md">
                          <button class="btn btn-primary-light sign-in" routerLink="login">Sign In</button>
                      </li>
                      <li class="hidden visible-xs visible-sm visible-md">
                          <button class="btn btn-primary focus-hero" style="float: right;"
                              (click)="startRegistration()">Try It</button>
                      </li>
                  </ul>
              </div>
              <div class="col-lg-3 text-right">
                  <button class="btn btn-primary-light hidden visible-lg" style="float: left;" routerLink="login">Sign
                      In</button>
                  <button class="btn btn-primary focus-hero hidden visible-lg" style="float: right;"
                      (click)="startRegistration()">Try It</button>
              </div>
          </div>
      </nav>
  </div>
</header>

<main id="start" class="fake-body">

  <section>
    <div class="container">
      <div class="text-center">
        <h1>Alternative to Liscio</h1>
        <h2>Choosing your Client Communication platform</h2>
      </div>
      <h4>Client Hub versus Liscio</h4>
      <p>Have you ever seen a comparison page where the “other option” is realistically compared to the company writing
        the page? Neither have we. </p>
      <p>But, instead of trying to turn this into a sales page, we want to turn these into useful comparisons that
        highlight the key differences between the options and let you make an informed decision on what is right for
        your firm.</p>
      <h4>Summary</h4>
      <p>Client Hub and Liscio are two platforms that are solving similar problems for firms. Your choice may come down
        to your firms’ unique workflow and client experience preferences -- and overall value.</p>
      <p>While we compare pricing and features below, what makes Client Hub special is our end-to-end experience, which
        has 3 key aspects:</p>
      
      <div class="row d-flex align-items-center illustration-section">
        <div class="col-md-4">
          <img class="img-responsive" src="/assets/img/illustration-alternatives.png"/>
        </div>
        <div class="col-md-8">
          <ol>
            <li>“Instantly Obvious”: Our workspace concept is so simple that clients use our platform without any learning curve</li>
            <li>Modern Metaphors: Each and every feature is lightweight and modern, e.g. communication similar to text messaging</li>
            <li>Client-Centric: Everything is organized by client, fitting the natural mental model of accounting professionals</li>
          </ol>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h4>Client Hub</h4>
          <p>If you prefer a lighter messaging-like communication model, cloud file management that includes folders and
            files, and to automate resolving QuickBooks unclassified expenses -- at a transparent and affordable price
            point.</p>
        </div>
        <div class="col-md-6">
          <h4>Liscio</h4>
          <p>If you prefer for a more email-like communication model, files organized by tags, and built-in eSign -- but
            you may be wondering if someone else got a better deal.</p>
        </div>
      </div>

      <h3 class="text-center">Pricing Comparison</h3>
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <th></th>
            <th style="width: 20%; text-align: center;">Client Hub<br>Standard</th>
            <th style="width: 20%; text-align: center;">Client Hub<br>Pro</th>
            <th style="width: 20%; text-align: center; vertical-align: middle;">Liscio</th>
          </tr>
        </thead>
        <tbody style="text-align: center;">
          <tr>
            <th scope="row">Plans start at</th>
            <td>$35/month</td>
            <td>$45/month</td>
            <td>Not publicly disclosed</td>
          </tr>
        </tbody>
      </table>
      <div class="button-wrapper text-center">
        <button (click)="startRegistration()" class="btn btn-primary btn-lg">Get a Demo</button>
      </div>

      <h3 class="text-center">Comparison of Key Features</h3>
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <th></th>
            <th style="width: 20%; text-align: center;">Client Hub<br>Standard</th>
            <th style="width: 20%; text-align: center;">Client Hub<br>Pro</th>
            <th style="width: 20%; text-align: center;">Liscio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Platforms</strong></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Browser-based</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Mobile App</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><strong>End-to-end Experience</strong></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">“Instantly Obvious”</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center">&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Modern Metaphors</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center">&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Client-Centric</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center">&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Automated Client Tasks</strong></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;"> Resolve QuickBooks uncategorized expenses </span></td>
            <td class="text-center">&nbsp;</td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center">&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Client Workspaces</strong></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Communication</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">File Sharing</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Client Tasks</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Client Apps/Resources</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Your Branding</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><strong>File Management</strong></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Create Folders</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">File Uploading</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Online Preview</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Windows/Mac Sync</span></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Collaborative Documents</strong></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Office 365 Co-Editing</span></td>
            <td><span style="font-weight: 400;">(Easily link docs in Google/Office 365)</span></td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Request eSignature</span></td>
            <td><span style="font-weight: 400;">(Easily link to Docusign etc.)</span></td>
            <td class="color-blue">&nbsp;</td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><strong>Client Communication</strong></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Text-like Messaging with Clients</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Email-like interaction “Compose Messages” etc.</span></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Mobile App Notifications</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><strong>Manage Client Tasks&nbsp;</strong></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Assign Tasks to Clients</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">View Outstanding Tasks across Clients</span></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td class="text-center color-blue"><strong>✔</strong></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Internal Tasks</strong></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">One-off and recurring jobs</span></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Assign and track jobs&nbsp; by staff</span></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td class="text-center"><strong>✔</strong></td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Task checklists for jobs</span></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><span style="font-weight: 400;">Dashboard of open jobs</span></td>
            <td>&nbsp;</td>
            <td class="color-blue">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <div class="button-wrapper text-center">
        <button (click)="startRegistration()" class="btn btn-primary btn-lg">Get a Demo</button>
      </div>

      <p class="text-center" style="font-size: 1rem; font-style: italic; margin-top: 60px;">Note: Comparison is current
        as of April 2020</p>
    </div>

  </section>

  <footer>
    <div class="container footer-top">
      <div class="row">
        <div class="col-md-3">
          <div class="footer-title">Comparisons</div>
          <a href="/#/alternative/sharefile" target="_blank" rel="noreferrer noopener">Alternative to ShareFile</a>
          <a href="/#/alternative/smartvault" target="_blank" rel="noreferrer noopener">Alternative to SmartVault</a>
          <a href="/#/alternative/liscio" target="_blank" rel="noreferrer noopener">Alternative to Liscio</a>
        </div>
        <div class="col-md-3">
          <div class="footer-title">Guides</div>
          <a href="https://blog.clienthub.me/2-simple-models-to-share-and-manage-files-with-client-hub/"
            target="_blank" rel="noreferrer noopener">Sharing Files with Client Hub</a>
          <a href="/#/quickbooks" target="_blank" rel="noreferrer noopener">QuickBooks Integration</a>
          <a href="https://blog.clienthub.me/category/firm-spotlight/" target="_blank" rel="noreferrer noopener">Firm Spotlights</a>
          <a (click)="openModal(availabilityModal)">Client Hub Service Uptime</a>
        </div>
        <div class="col-md-3">
          <div class="footer-title">Solutions</div>
          <a href="https://clienthub.me/solutions/secure-client-portal.html" target="_blank" rel="noreferrer noopener">Secure Client Portal</a>
          <a href="https://clienthub.me/solutions/secure-file-sharing.html" target="_blank" rel="noreferrer noopener">Secure File Sharing</a>
        </div>
        <div class="col-md-3">
          <div class="footer-title">About</div>
          <a href="https://blog.clienthub.app/" target="_blank" rel="noreferrer noopener">Blog</a>
          <a href="https://www.getrevue.co/profile/clienthub" target="_blank" rel="noreferrer noopener">Newsletter</a>
          <a href="https://clienthub.app/#/about-us" target="_blank" rel="noreferrer noopener">About Us</a>
          <address>
            <div>
              3615 SW13 Street, Suite 7<br>
              Gainesville FL 32608, USA
            </div>
            <div>
              Phone number: +1-352-559-6321<br>
              Email Us: info at clienthub.app
            </div>
          </address>
        </div>
      </div>
      <hr>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-sm-7 terms">
            <a routerLink="/terms-of-service" style="margin-right: 15px;">Terms of Service</a>
            <a routerLink="/privacy-policy" style="margin-right: 15px;">Privacy Policy</a>
            <a routerLink="/data-security">Data Security</a>
          </div>
          <div class="col-sm-5 text-right social">
            <a href="https://www.facebook.com/ClientHubApp/" target="_blank" rel="noreferrer noopener"><i class="fa fa-facebook-official"></i></a>
            <a href="https://twitter.com/@clienthubapp" target="_blank" rel="noreferrer noopener"><i class="fa fa-x-twitter"></i></a>
            <a href="https://www.linkedin.com/company/11359354/" target="_blank" rel="noreferrer noopener"><i class="fa fa-linkedin"></i></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</main>

<ng-template #availabilityModal class="modal-lg">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i class="feather x"></i></button>
    <h4 class="modal-title" id="myModalLabel">Availability</h4>
  </div>
  <div class="modal-body"
    style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
    <iframe style="width:100%;height:100%;" src="https://clienthub-609.freshstatus.io/" frameborder="0"></iframe>
  </div>
</ng-template>

<ng-template #demoModal class="modal-lg">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i class="feather x"></i></button>
    <h4 class="modal-title" id="myModalLabel">Watch it now</h4>
  </div>
  <div class="modal-body"
    style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
    <iframe style="width:100%;height:100%;"
      src="https://pipedrivewebforms.com/form/c8e66402a250d860e9fb210c117b566b5203832" frameborder="0"></iframe>
  </div>
</ng-template>

<ng-template #demoScheduleModal class="modal-lg">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i class="feather x"></i></button>
    <h4 class="modal-title" id="myModalLabel">Schedule meeting</h4>
  </div>
  <div class="modal-body"
    style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
    <iframe style="width:100%;height:100%;" src="https://calendly.com/judiech/discussion?month=2020-01"
      frameborder="0"></iframe>
  </div>
</ng-template>
<app-sign-up-drawer [signUpDrawerOpen]="signUpDrawerOpen" (signUpDrawerToggle)="signUpDrawerToggle($event)"></app-sign-up-drawer>