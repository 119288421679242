import {
  Component,
  OnInit,
  TemplateRef,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import * as _ from "underscore";
import { Intercom } from "@supy-io/ngx-intercom";
import { SignUpDrawerComponent } from "../../landing-page/sign-up-drawer/sign-up-drawer.component";
import { FooterComponent } from "../../landing-page/footer/footer.component";
import { NavigationComponent } from "../../landing-page/navigation/navigation.component";

declare var jQuery: any;

@Component({
    selector: "quickbooks-landing-page",
    templateUrl: "./quickbooks-landing-page.component.html",
    styleUrls: ["./quickbooks-landing-page.component.scss"],
    imports: [
        NavigationComponent,
        FooterComponent,
        SignUpDrawerComponent,
    ]
})
export class QuickBooksLandingPageComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public modalRef: BsModalRef;
  public email: String = "";
  public password: String = "";
  public fragment: string;
  public data: any = this.localStorage.get("data");
  public playVideo = 0;
  public currentFeature: String = "conversations";
  public signUpDrawerOpen: Boolean = false;
  isTrial = false;

  constructor(
    public localStorage: LocalStorageService,
    public router: Router,
    public route: ActivatedRoute,
    public modalService: BsModalService,
    public cdRef: ChangeDetectorRef,
    public intercom: Intercom
  ) {
    //
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.intercom.boot({
      app_id: "wqv1cgow",
    });

    var features = [
      "conversations",
      "tracked-items",
      "apps",
      "resources",
      "files",
      "quickbooks",
    ];

    var shouldStartFeatures = true;
    var currentFeature = 0;
    var timer = 0;

    function pauseFeatures() {
      clearInterval(timer);
    }

    function startFeatures() {
      if (shouldStartFeatures) {
        pauseFeatures();

        shouldStartFeatures = false;
        currentFeature = 0;

        // Start from first
        jQuery(".item-features").removeClass("active").removeClass("full");
        jQuery(".item-features.item-" + features[currentFeature]).addClass(
          "full"
        );
        jQuery("#features").attr("class", "");
        jQuery("#features").addClass(features[currentFeature]);
        setTimeout(function () {
          jQuery(".item-features.item-" + features[currentFeature])
            .addClass("active")
            .removeClass("full");
        }, 0);

        // Create the timer
        timer = window.setInterval(function () {
          currentFeature = currentFeature + 1;
          console.log(currentFeature);
          // Restart
          if (currentFeature == 6) {
            currentFeature = 0;
          }

          jQuery("#features").attr("class", "");
          jQuery(".item-features").removeClass("active").removeClass("full");
          jQuery("#features").addClass(features[currentFeature]);
          jQuery(".item-features.item-" + features[currentFeature]).addClass(
            "active"
          );
        }, 6500);
      }
    }

    function resumeFeatures() {
      pauseFeatures();

      // Start from first
      jQuery(".item-features").removeClass("active").removeClass("full");
      jQuery(".item-features.item-" + features[currentFeature]).addClass(
        "full"
      );
      jQuery("#features").attr("class", "");
      jQuery("#features").addClass(features[currentFeature]);
      setTimeout(function () {
        jQuery(".item-features.item-" + features[currentFeature])
          .addClass("active")
          .removeClass("full");
      }, 0);

      // Create the timer
      timer = window.setInterval(function () {
        currentFeature = currentFeature + 1;
        console.log(currentFeature);
        // Restart
        if (currentFeature == 6) {
          currentFeature = 0;
        }

        jQuery("#features").attr("class", "");
        jQuery(".item-features").removeClass("active");
        jQuery("#features").addClass(features[currentFeature]);
        jQuery(".item-features.item-" + features[currentFeature]).addClass(
          "active"
        );
      }, 6500);
    }

    jQuery("body").on(
      "mouseenter",
      ".items-features-wrapper .active",
      function () {
        pauseFeatures();
      }
    );

    jQuery("body").on(
      "mouseleave",
      ".items-features-wrapper .active",
      function () {
        resumeFeatures();
      }
    );

    jQuery("body").on(
      "mouseenter",
      ".items-features-wrapper .item-features",
      function () {
        currentFeature = jQuery(this).data("feature");
        resumeFeatures();
      }
    );

    jQuery(window).on("scroll", function () {
      var scrollPosition = jQuery(this).scrollTop(),
        windowHeight = jQuery(window).height(),
        featuresPosition = jQuery(".features-mini-wrapper").offset().top;

      if (
        (scrollPosition > 0 &&
          scrollPosition < featuresPosition - windowHeight / 2.5) ||
        scrollPosition > featuresPosition + windowHeight / 2.5
      ) {
        jQuery(".top-bar").addClass("fixed");
      } else {
        jQuery(".top-bar").removeClass("fixed");
      }

      if (scrollPosition > featuresPosition - windowHeight / 2.5) {
        jQuery(".items-features-sticky").addClass("sticky");
        startFeatures();
      } else {
        jQuery(".items-features-sticky").removeClass("sticky");
        shouldStartFeatures = true;
      }

      if (scrollPosition > featuresPosition + windowHeight) {
        shouldStartFeatures = true;
      }
    });
  }

  ngOnInit(): void {
    //   document.getElementsByClassName('grv-widget-tag')[2]['style'].display = 'none';
    const node = document.querySelector("body");
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.previousSibling !== undefined &&
          mutation.previousSibling !== null
        ) {
          if (
            mutation.previousSibling["className"] !== undefined &&
            mutation.previousSibling["className"] !== null
          ) {
            if (
              mutation.previousSibling["className"].indexOf(
                "grv-widget-tag"
              ) !== -1
            ) {
              if (this.data.user_type !== 1) {
                let element = jQuery(
                  document.getElementsByClassName("grv-widget-tag")[2]
                );
                if (!_.isEmpty(element)) {
                  jQuery(
                    document.getElementsByClassName("grv-widget-tag")[2]
                  )[0]["style"].visibility = "hidden";
                  observer.disconnect();
                }
              }
            }
          }
        }
      });
    });

    observer.observe(node, {
      attributes: true,
      childList: true,
      characterData: true,
    });

    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });

    // jQuery.getScript("https://widget.prefinery.com/widget/v2/7u96gn7g.js");

    jQuery(document).ready(function () {
      var winPosition = jQuery(window).scrollTop();
      var winHeight = jQuery(window).height();

      if (winPosition > 20) {
        jQuery(".top-bar").addClass("fixed");
      } else {
        jQuery(".top-bar").removeClass("fixed");
      }

      jQuery(".menu-icon").click(function () {
        if (jQuery(".nav-list").hasClass("open")) {
          jQuery(".nav-list").removeClass("open");
        } else {
          jQuery(".nav-list").addClass("open");
        }
      });

      jQuery(".nav-list li a").click(function () {
        jQuery(".nav-list").removeClass("open");
      });

      jQuery(".focus-hero").click(function () {
        jQuery(".input-hero").removeClass("animated flash");
        jQuery("html, body").animate({ scrollTop: 0 }, 500, function () {
          setTimeout(function () {
            jQuery(".input-hero").addClass("animated flash");
            jQuery(".input-hero").focus();
          }, 300);
        });
      });
    });

    jQuery(window).on("load", function () {
      var element = window.location.hash.split("/")[1];
      setTimeout(function () {
        jQuery("html, body").animate(
          { scrollTop: jQuery(element).offset().top },
          0
        );
      }, 100);
    });
  }

  public customPricing(): void {
    this.intercom.showNewMessage("Hi, I want a price quote.");
  }

  public customDemo(): void {
    this.intercom.showNewMessage("Hi, I want to schedule a 1/1 demo.");
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }

  startRegistration(): void {
    // this.localStorage.set("registration_email", this.email);
    // this.router.navigate(["/registration/join"]);
    this.signUpDrawerToggle(true);
  }

  signUpDrawerToggle(x: boolean, trial = false): void {
    this.isTrial = trial;
    this.signUpDrawerOpen = x;
  }
}
