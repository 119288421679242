<div class="loading" [hidden]="!show_loading">
  <div class="loader">Loading...</div>
</div>

<!-- Step 1 -->
<div class="container">
  <div class="row d-flex full-height">
    <div class="col-sm-5 shade-bg d-flex flex-column">
      <img src="assets/img/logo-default.svg" width="150" alt="Client Hub" />

      <div class="headline">
        <h6 style="text-transform: uppercase; opacity: 0.8; font-weight: 500">
          Step {{ currStep }} of 2
        </h6>
        <div *ngIf="currStep === 1">
          <h2 class="mb-2">Start a Trial</h2>
          <p style="color: #151c29; font-weight: 500">
            Your free trial will be at <span class="strong">Premium level</span>,
            and you can explore all our features for free.
            <a (click)="isCollapsed = !isCollapsed">Know more</a>.
          </p>
          <div *ngIf="!isCollapsed">
            <p style="font-size: 14px">
              If you don't need the Premium features, go to the Billing Portal
              within Client Hub and change your plan to Standard at any point during
              or after your trial.
            </p>
          </div>
        </div>
        <h2 *ngIf="currStep === 2">Check Email for Activation Link</h2>
      </div>

      <div class="illustration" style="align-items: flex-start; margin-top: 243px">
        <div class="testimonial">
          <div class="testimonial-avatar-name">
            <div class="testimonial-avatar" style="background: url(assets/img/landing-page-v3/blake.png)"></div>
            <div class="testimonial-name">
              Blake Oliver<br />
              <strong>Founder &#64; Earmark App</strong>
            </div>
          </div>
          <div class="testimonial-text">
            When I ran my firm, I dreamed about having an app just like this
            that can solve these exact pain points. Bravo to Client Hub for
            actually doing it.
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 offset-sm-1">
      <!-- Form -->
      <div class="form" *ngIf="currStep === 1">
        <div class="form-group">
          <label for="firmname">Firm name</label>
          <input required [(ngModel)]="accountingCompanyName" type="text" id="firmname" class="form-control"
            placeholder="Firm name" autofocus />
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="firstname">First name</label>
              <input required [(ngModel)]="ownerFirstName" type="text" id="firstname" class="form-control"
                placeholder="Your first name" />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="lastname">Last name</label>
              <input required [(ngModel)]="ownerLastName" type="text" id="lastname" class="form-control"
                placeholder="Your last name" />
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="email">Work email</label>
          <p style="font-size: 14px">
            Use your work email, so that you don't miss key client or team
            notifications
          </p>
          <input required [(ngModel)]="ownerEmail" type="email" id="email" class="form-control"
            placeholder="Your work email address" />
          <p style="font-size: 1rem; color: red" *ngIf="lastValidatedEmail == ownerEmail && !validEmail">
            {{ emailErrorMessage }}
          </p>
        </div>

        <div class="form-group">
          <label for="phone">Phone</label>
          <input [(ngModel)]="ownerPhone" type="text" id="phone" class="form-control" placeholder="Phone number" />
        </div>

        <!-- Password -->
        <div class="password-fields">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="password">Your Account Password</label>
                <input [(ngModel)]="password" type="password" id="password" class="form-control"
                  placeholder="Your password" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="confirmpassword">Confirm Password</label>
                <input [(ngModel)]="confirmpassword" type="password" id="confirmpassword" class="form-control"
                  placeholder="Confirm your password" (keyup.enter)="moveToStep2()" />
              </div>
            </div>
          </div>
          <!-- Pass requirements -->
          <div class="password-requirements mb-5">
            <strong>Password requirements</strong>
            <div class="row">
              <div class="col-6">
                <div class="requirement" [ngClass]="{ 'green-text': hasCapitalLetter() }">
                  <i class="feather" [ngClass]="{
                      circle: !hasCapitalLetter(),
                      'check-circle': hasCapitalLetter()
                    }"></i>
                  Capital letters
                </div>
                <div class="requirement" [ngClass]="{ 'green-text': hasLowerCaseLetter() }">
                  <i class="feather" [ngClass]="{
                      circle: !hasLowerCaseLetter(),
                      'check-circle': hasLowerCaseLetter()
                    }"></i>
                  Lower-case letters
                </div>
                <div class="requirement" [ngClass]="{ 'green-text': hasSymbols() }">
                  <i class="feather" [ngClass]="{
                      circle: !hasSymbols(),
                      'check-circle': hasSymbols()
                    }"></i>
                  Symbols
                </div>
              </div>
              <div class="col-6">
                <div class="requirement" [ngClass]="{ 'green-text': hasNumbers() }">
                  <i class="feather" [ngClass]="{
                      circle: !hasNumbers(),
                      'check-circle': hasNumbers()
                    }"></i>
                  Numbers
                </div>
                <div class="requirement" [ngClass]="{ 'green-text': hasEightChars() }">
                  <i class="feather" [ngClass]="{
                      circle: !hasEightChars(),
                      'check-circle': hasEightChars()
                    }"></i>
                  At least 8 characters
                </div>
                <div class="requirement" [ngClass]="{ 'green-text': isConfirmPasswordSame() }">
                  <i class="feather" [ngClass]="{
                      circle: !isConfirmPasswordSame(),
                      'check-circle': isConfirmPasswordSame()
                    }"></i>
                  Confirm password
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p style="font-size: 13px">
            By clicking to continue, you agree to our
            <a routerLink="/terms-of-service" target="_blank" rel="noreferrer noopener">Terms of Service</a>
            and
            <a routerLink="/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a>.
          </p>
        </div>

        <div class="footer">
          <button class="btn btn-success btn-lg btn-block" (click)="moveToStep2()"
            [disabled]="validateForm() || validatePassword()">
            Continue
          </button>
        </div>
      </div>

      <!-- Second Step -->
      <div class="form init-hidden" [ngClass]="{ visible: currStep === 2 }">
        <h4>
          In 2 minutes, we will send a link to your email address to activate your
          account.
        </h4>
        <p>
          Please wait and then check your email box (including junk folder) for an
          email titled
          <span style="font-weight: 600; color: #000">Thank You for joining Client Hub</span>.
        </p>
        <div>
          <div class="alert alert-primary" role="alert"
            style="cursor:default; float:none; display: flex; align-items: center; gap: 8px; border-radius: 16px;">
            <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.578 8.4584L12.6148 8.4716L18.4016 3.6744C18.2756 3.6292 18.1416 3.6004 18 3.6004H2C1.8584 3.6004 1.7244 3.6296 1.5984 3.6748L9.4836 10.2128C9.7872 10.4628 10.2132 10.4628 10.5176 10.212L11.2284 9.6232C11.1676 9.2892 11.2616 8.9504 11.5056 8.706C11.7892 8.4224 12.1992 8.3288 12.5784 8.4588L12.578 8.4584Z"
                fill="#0084FF" style="fill:#0084FF;fill:color(display-p3 0.0000 0.5176 1.0000);fill-opacity:1;" />
              <path
                d="M11.0268 10.828C10.724 11.0772 10.362 11.202 9.9996 11.202C9.6372 11.202 9.2756 11.0772 8.974 10.8284L7.8772 9.9188L1.5984 15.1256C1.7244 15.1708 1.8584 15.2 2 15.2H13.2128L11.4964 10.4392L11.0268 10.828Z"
                fill="#0084FF" style="fill:#0084FF;fill:color(display-p3 0.0000 0.5176 1.0000);fill-opacity:1;" />
              <path d="M13.4888 8.7864L19.2 10.8444V4.8C19.2 4.5776 19.1352 4.3716 19.0292 4.1932L13.4888 8.7864Z"
                fill="#0084FF" style="fill:#0084FF;fill:color(display-p3 0.0000 0.5176 1.0000);fill-opacity:1;" />
              <path
                d="M0.799999 14C0.799999 14.2224 0.864799 14.428 0.970799 14.6068L7.2504 9.3992L0.970799 4.1932C0.864799 4.372 0.799999 4.5776 0.799999 4.8V14Z"
                fill="#0084FF" style="fill:#0084FF;fill:color(display-p3 0.0000 0.5176 1.0000);fill-opacity:1;" />
              <path
                d="M23.0504 13.082L12.3116 9.2124C12.2848 9.2032 12.2592 9.1996 12.236 9.1996C12.154 9.1996 12.0956 9.2464 12.0712 9.2712C12.0396 9.3028 11.9708 9.3904 12.0144 9.5164L15.8812 20.2432C15.932 20.3872 16.0528 20.3948 16.1024 20.4C16.1512 20.3992 16.2696 20.3828 16.3152 20.2424L17.4816 16.756C17.5252 16.6248 17.6344 16.5256 17.7692 16.4936C17.9044 16.462 18.046 16.5024 18.144 16.6004L20.7896 19.2464C20.8916 19.3484 21.0628 19.3476 21.164 19.2464L22.046 18.3644C22.1476 18.2628 22.1476 18.0916 22.046 17.99L19.4004 15.344C19.3024 15.246 19.262 15.104 19.2936 14.9692C19.3256 14.8344 19.4248 14.7256 19.556 14.6816L23.0384 13.5164C23.0384 13.5164 23.0404 13.5156 23.0416 13.5152C23.1828 13.4696 23.1996 13.3492 23.2 13.3C23.2008 13.2504 23.1876 13.1304 23.0504 13.0816V13.082Z"
                fill="#0084FF" style="fill:#0084FF;fill:color(display-p3 0.0000 0.5176 1.0000);fill-opacity:1;" />
            </svg>
            <span style="color: #0084FF;">You need to click the link in the email to activate your Client Hub
              trial.</span>
          </div>
        </div>
        <hr class="mt-5 mb-5" style="border-top: 1px solid #797b7d;" />
        <h5>While you wait for this activation email...</h5>
        <p>Please check out this very short orientation video:</p>
        <script src="https://fast.wistia.com/embed/medias/959gxifoam.jsonp" async></script>
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
        <span class="wistia_embed wistia_async_959gxifoam popover=true popoverContent=link videoFoam=false">
          <a href="#" id="wistia-trigger">
            <img style="cursor: pointer" src="assets/img/drawer-video.png" class="img-fluid img-responsive" style="width:100%" />
          </a>
        </span>
      </div>
    </div>
  </div>
</div>