import {
  Component,
  OnInit,
  TemplateRef /*ViewContainerRef*/,
} from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FooterComponent } from "./footer/footer.component";
import { NavigationComponent } from "./navigation/navigation.component";

declare var jQuery: any;

@Component({
    selector: "about-us",
    templateUrl: "./about-us.component.html",
    styleUrls: ["./about-us.component.css"],
    imports: [NavigationComponent, FooterComponent]
})
export class AboutUsComponent implements OnInit {
  public modalRef: BsModalRef;
  public email: String = "";
  public password: String = "";
  public fragment: string;

  constructor(
    public localStorage: LocalStorageService,
    public router: Router,
    public route: ActivatedRoute,
    public modalService: BsModalService, // vcr: ViewContainerRef
  ) {
    //
  }

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
    jQuery.getScript("https://widget.prefinery.com/widget/v2/7u96gn7g.js");
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }
}
