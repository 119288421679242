<div id="start" class="fake-body">
    <header id="top-bar" class="website-navigation">
        <div class="container animated fadeInDown">
            <div class="row">
                <div class="col-8 col-sm-3">
                    <a href="https://clienthub.app/">
                        <img class="logo-website" src="assets/img/logo-default.svg" alt="Client Hub Logo">
                    </a>
                </div>
                <div class="col-4 col-sm-9">
                    <i class="visible-xs feather menu menu-icon"></i>
                    <nav class="text-right " role="navigation">
                        <a href="https://clienthub.app/">Home</a>
                    </nav>
                </div>
            </div>
        </div>
    </header>

    <div class="header">
        <div class="container">
            <div class="row d-flex align-items-center">
                <div class="col-md-4">
                    <h1>At Client Hub, the security of your data is our highest priority</h1>
                </div>
                <div class="col-md-8">
                    <img class="img-responsive" src="assets/img/data-security-header.png" alt="Data Security">
                </div>
            </div>
        </div>
    </div>
    <section class="terms-page data-security animated fadeInUp">
        <div class="container">
            <h3>Secure Communication for Firms</h3>
            <ul>
                <li>Client Hub is founded on providing additional digital security for professional services firms</li>
                <li>Currently, most client communication is over email. This is clearly a huge vulnerability when it comes to risks of email which is unencrypted and very susceptible to phishing attacks</li>
                <li>Client Hub moves this communication to a secure, invite-only platform</li>
            </ul>

            <h3>Protecting your data</h3>
            <div class="row d-flex align-items-center">
                <div class="col-md-3">
                    <img class="img-responsive" src="assets/img/infrastructure.svg" alt="Modern, secure infrastructure">
                </div>
                <div class="col-md-9">
                    <h4>1. Modern, secure infrastructure</h4>
                    <p>Modern, secure infrastructure
                        Our application is hosted and served through Google Cloud Platform and Amazon Web Services.
                        These
                        platforms are
                        certified under various ISO and SOC compliance standards as detailed here <a
                            href="https://cloud.google.com/security/" target="_blank" rel="noreferrer noopener"
                            rel="noopener noreferrer">https://cloud.google.com/security/</a> and
                        <a href="https://aws.amazon.com/security/" target="_blank" rel="noreferrer noopener"
                            rel="noopener noreferrer">https://aws.amazon.com/security/</a>
                        . All data is hosted in these providers in US-based data centers.</p>
                </div>
            </div>

            <div class="row d-flex align-items-center">
                <div class="col-md-3">
                    <img class="img-responsive" src="assets/img/encryption.svg" alt="Encryption of data and files">
                </div>
                <div class="col-md-9">
                    <h4>2. Encryption of data and files</h4>
                    <p>Client Hub safeguards your data and files in transit with industry standard encryption which can
                        be
                        verified with the
                        lock icon in the browser bar. Files shared by users in Client Hub are also secured at rest with
                        256-bit
                        encryption.</p>
                </div>
            </div>

            <div class="row d-flex align-items-center">
                <div class="col-md-3">
                    <img class="img-responsive" src="assets/img/testing.svg" alt="Rigorous testing">
                </div>
                <div class="col-md-9">
                    <h4>3. Rigorous testing</h4>
                    <p>Client Hub does regular testing of the application with security tools to assess against latest security vulnerabilities and requirements.</p>
                </div>
            </div>

            <h3>Protecting your privacy</h3>
            <p>Please review our <a routerLink="/privacy-policy">Privacy Policy</a> that details our practices
                regarding data privacy.</p>
        </div>
    </section>

    <footer>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-sm-7 terms">
                        <a routerLink="/terms-of-service" style="margin-right: 15px;">Terms of Service</a>
                        <a routerLink="/privacy-policy" style="margin-right: 15px;">Privacy Policy</a>
                        <a routerLink="/data-security">Data Security</a>
                    </div>
                    <div class="col-sm-5 text-right social">
                        <a href="https://www.facebook.com/ClientHubApp/" target="_blank" rel="noreferrer noopener"><i
                                class="fa fa-facebook-official"></i></a>
                        <a href="https://twitter.com/@clienthubapp" target="_blank" rel="noreferrer noopener"><i class="fa fa-x-twitter"></i></a>
                        <a href="https://www.linkedin.com/company/11359354/" target="_blank" rel="noreferrer noopener"><i
                                class="fa fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>