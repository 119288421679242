import {
  Component,
  OnInit,
  TemplateRef /*ViewContainerRef*/,
} from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { Router, NavigationEnd, RouterLink } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: "cookie-policy",
    templateUrl: "./cookie-policy.component.html",
    styleUrls: ["./website.component.css"],
    imports: [RouterLink]
})
export class CookiePolicyComponent implements OnInit {
  public modalRef: BsModalRef;
  public email: String = "";
  public password: String = "";

  constructor(
    public localStorage: LocalStorageService,
    public router: Router,
    public modalService: BsModalService
  ) {
    //
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }
}
