import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SignUpDrawerComponent } from "../../sign-up-drawer/sign-up-drawer.component";
import { FooterComponent } from "../../footer/footer.component";
import { NavigationComponent } from "../../navigation/navigation.component";

declare var jQuery: any;
@Component({
    selector: "app-product-tour",
    templateUrl: "./product-tour.component.html",
    styleUrls: ["../../landing-page.component.scss"],
    imports: [
        NavigationComponent,
        FooterComponent,
        SignUpDrawerComponent,
    ]
})
export class ProductTourComponent implements OnInit {
  public modalRef: BsModalRef;
  public email: String = "";
  public data: any = this.localStorage.get("data");
  public sectionToDisplay: String = "";
  public signUpDrawerOpen: Boolean = false;

  constructor(
    public localStorage: LocalStorageService,
    public router: Router,
    public modalService: BsModalService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    jQuery(".features-list li").on("click", function () {
      var newImage =
        "assets/img/landing-page-v4/product-tour/" + jQuery(this).data("img");
      var previewId = jQuery(this).data("img").charAt(0);
      jQuery(".feature-preview-" + previewId)
        .find("img")
        .attr("src", newImage);
      jQuery(this).parent().find("li").removeClass("active");
      jQuery(this).addClass("active");
    });
  }

  startRegistration(): void {
    // this.localStorage.set("registration_email", this.email);
    // this.router.navigate(["/registration/join"]);
    this.signUpDrawerToggle(true);
  }

  signUpDrawerToggle(x): void {
    this.signUpDrawerOpen = x;
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }
}
