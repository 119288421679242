import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { SimpleChanges } from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { HotToastService } from "@ngneat/hot-toast";
import {
  SharedDataService,
  SubscriptionType,
} from "src/app/dashboard/shared-data.service";
import { TaggingService } from "src/app/dashboard/tagging.service";
import { LoginService } from "src/app/login/login.service";
import { CreateService } from "src/app/registration/create/create.service";
import { RegistrationService } from "src/app/registration/registration.service";
import { RouterLink } from "@angular/router";
import { NgxIntlTelInputModule } from "@justin-s/ngx-intl-tel-input";
import { FormsModule } from "@angular/forms";
import { NgIf, NgClass } from "@angular/common";

export {};
declare global {
  interface Window {
    Calendly: any;
    dataLayer: any[];
  }
}
@Component({
    selector: "app-trial-drawer",
    templateUrl: "./trial-drawer.component.html",
    styleUrls: ["./trial-drawer.component.scss"],
    imports: [NgIf, FormsModule, NgxIntlTelInputModule, NgClass, RouterLink]
})
export class TrialDrawerComponent implements OnInit {
  show_loading: boolean = false;

  shortTrial: boolean = false;
  currStep: number = 1;

  @Output() signUpDrawerToggle = new EventEmitter<boolean>();

  public teamSize = "";
  accountingCompanyName = "";
  ownerFirstName = "";
  ownerLastName = "";
  ownerEmail = "";
  ownerPhone = "";
  password = "";
  confirmpassword = "";

  validEmail = true;
  lastValidatedEmail = "";
  emailErrorMessage = "";

  isCollapsed = true;

  constructor(
    private createService: CreateService,
    private localStorage: LocalStorageService,
    private loginService: LoginService,
    private toastr: HotToastService,
    private taggingService: TaggingService,
    private registrationService: RegistrationService,
  ) {}

  ngOnInit(): void {}

  @HostListener("window:message", ["$event"]) onPostMessage(event) {
    if (
      this.isCalendlyEvent(event) &&
      event.data.event == "calendly.event_scheduled"
    ) {
      console.log("Calendly Scheduled");
      this.moveToStep2();
    }
  }

  isCalendlyEvent(e: any) {
    return e.data.event && e.data.event.indexOf("calendly") === 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    changes.signUpDrawerOpen.currentValue
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");

    if (document.getElementById("firstname") != null) {
      document.getElementById("firstname").focus();
    }
    console.log("ngOnChanges");
  }

  ngOnDestroy(): void {
    this.closeDrawer();
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    this.closeDrawer();
  }

  focusFirstName() {
    if (document.getElementById("firstname") != null) {
      document.getElementById("firstname").focus();
    }
  }

  validateEmail(email: string) {
    const validRegex = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    // true = disable the button, so we check the opposite
    return (
      (this.lastValidatedEmail == this.ownerEmail && !this.validEmail) ||
      !validRegex
    );
  }

  validateForm() {
    return (
      this.accountingCompanyName == "" ||
      this.ownerFirstName == "" ||
      this.ownerLastName == "" ||
      this.ownerPhone == "" ||
      this.validateEmail(this.ownerEmail)
    );
  }

  hasCapitalLetter() {
    return String(this.password).match(/([A-Z])/);
  }

  hasLowerCaseLetter() {
    return String(this.password).match(/([a-z])/);
  }

  hasSymbols() {
    return String(this.password).match(/[-!@$%#^&*()_+|~=`{}\[\]:";'<>?,.\/]/);
  }

  hasNumbers() {
    return String(this.password).match(/([0-9])/);
  }

  hasEightChars() {
    return this.password.length >= 8;
  }

  isConfirmPasswordSame() {
    return this.password && this.password == this.confirmpassword;
  }

  validatePassword() {
    return !(
      this.hasCapitalLetter() &&
      this.hasLowerCaseLetter() &&
      this.hasSymbols() &&
      this.hasNumbers() &&
      this.hasEightChars() &&
      this.isConfirmPasswordSame()
    );
  }

  async createTrialAccount() {
    if (this.validatePassword()) {
      return;
    }

    this.ensureFreshLogin();

    await this.taggingService.tagAnonymousUserPromise(
      this.ownerEmail,
      this.ownerFirstName,
      this.ownerLastName,
      null,
      null,
      this.ownerPhone,
    );

    let addUserResponse = await this.createService.add_user(
      this.ownerFirstName,
      this.ownerLastName,
      this.ownerEmail,
      this.password,
      1,
      1,
      null,
      null,
    );

    this.toastr.success("User created!");
    this.localStorage.remove("temp_email");
    this.localStorage.remove("temp_first_name");
    this.localStorage.remove("temp_last_name");
    this.localStorage.remove("access_token");
    this.localStorage.remove("refresh_token");
    this.localStorage.remove("data");
    this.localStorage.remove("user");
    this.localStorage.remove("company");
    this.localStorage.remove("pin_expires");

    this.localStorage.set("data", addUserResponse.data);
    this.localStorage.set("user", addUserResponse.user);
    this.localStorage.set("remember", true);

    let loginResponse = await this.loginService.login(
      this.ownerEmail,
      this.password,
    );

    this.localStorage.set("access_token", loginResponse.access);
    this.localStorage.set("refresh_token", loginResponse.refresh);
    this.localStorage.set("company", loginResponse.company);
    this.localStorage.set("subscription_type", loginResponse.subscription_type);

    this.createService
      .add_company(
        this.accountingCompanyName,
        null,
        true,
        null,
        SubscriptionType.PREMIUM,
        true,
        this.shortTrial,
      )
      .then(() => {
        this.ensureFreshLogin();
        this.currStep = 2;
        this.show_loading = false;
      });

    // Google tag manager event
    // window.dataLayer.push({ event: "Trial_Started" });
  }

  ensureFreshLogin() {
    this.localStorage.remove("data");
    this.localStorage.remove("user");
    this.localStorage.remove("company");
    this.localStorage.remove("remember");
    this.localStorage.remove("access_token");
    this.localStorage.remove("refresh_token");
  }

  closeDrawer(): void {
    this.signUpDrawerToggle.emit(false);
  }

  tagAnonymousUser() {
    this.taggingService
      .tagAnonymousUser(
        this.ownerEmail,
        this.ownerFirstName,
        this.ownerLastName,
        null,
        null,
        this.ownerPhone,
      )
      .subscribe(() => {});
  }

  moveToStep2(): void {
    // this.emailErrorMessage = "";
    // this.lastValidatedEmail = this.ownerEmail;
    // this.registrationService.check_invitation(this.ownerEmail).then(
    //   (res: any) => {
    //     this.tagAnonymousUser();
    //     document.getElementById("sign-up-drawer").scrollTo(0, 0);
    //     this.currStep = 2;
    //     window.Calendly.initInlineWidget({
    //       url: "https://calendly.com/d/3ch-g7d-gt6/client-hub-demo-discussion-trial-extension",
    //       parentElement: document.querySelector(".calendly-inline-widget"),
    //       prefill: {
    //         firstName: this.ownerFirstName,
    //         lastName: this.ownerLastName,
    //         email: this.ownerEmail,
    //       },
    //     });
    //   },
    //   (err: any) => {
    //     this.emailErrorMessage = err.error.message;
    //     this.validEmail = false;
    //     this.toastr.error(err.error.message);
    //   }
    // );
    this.show_loading = true;
    this.createTrialAccount();
  }

  moveToStep3(shortTrial = false): void {
    this.shortTrial = shortTrial;
    this.createTrialAccount();
    this.currStep = 3;
  }
}
