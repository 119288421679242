<div class="sign-up-drawer" [ngClass]="{ open: signUpDrawerOpen }">
  <div class="header">
    <div class="title">
      <h3 *ngIf="drawerState == 'initial'">Get a Demo</h3>
      <h3 *ngIf="drawerState == 'owner' && !_isTrial">Get a Demo</h3>
      <h3 *ngIf="drawerState == 'owner' && _isTrial">Start a Free Trial</h3>
      <h3 *ngIf="drawerState == 'trial'">Start a Free Trial</h3>
      <h3 *ngIf="drawerState == 'video'">Before you start</h3>
      <h3 *ngIf="drawerState == 'calendar' && _isTrial">
        Schedule the onboarding
      </h3>
      <h3 *ngIf="drawerState == 'calendar' && !_isTrial">Schedule a demo</h3>
      <h3 *ngIf="drawerState == 'password'">Password</h3>
    </div>
    <!-- <div class="close-btn" (click)="closeDrawer()">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="8" fill="#EBEDF0" />
        <rect
          width="18"
          height="2"
          rx="1"
          transform="matrix(0.707106 -0.707108 0.707106 0.707108 8.92896 21.6569)"
          fill="#485364"
        />
        <rect
          width="18"
          height="2"
          rx="1"
          transform="matrix(-0.707106 -0.707108 -0.707106 0.707108 23.071 21.6569)"
          fill="#485364"
        />
      </svg>
    </div> -->
  </div>

  <div class="content" *ngIf="['initial', 'trial'].includes(drawerState)">
    <div>
      <h4>Your Firm</h4>
      <div class="form-group">
        <label for="firmname">Firm name</label>
        <input
          required
          [(ngModel)]="accountingCompanyName"
          type="text"
          id="firmname"
          class="form-control"
          placeholder="Firm name"
          autofocus
        />
      </div>
      <div class="form-group">
        <label for="teamsize">Team size</label>
        <div class="radio-buttons">
          <label class="form-check">
            <input
              class="form-check-input"
              type="radio"
              [checked]="teamSize == '0'"
              [ngModelOptions]="{ standalone: true }"
              name="teamsize"
              id="teamsize1"
              [value]="'0'"
              [(ngModel)]="teamSize"
            />
            <span class="form-check-label" for="teamsize1"> 1-2 </span>
          </label>
          <label class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="teamsize"
              [checked]="teamSize == 1"
              [ngModelOptions]="{ standalone: true }"
              id="teamsize2"
              [value]="1"
              [(ngModel)]="teamSize"
            />
            <span class="form-check-label" for="teamsize2"> 3-5 </span>
          </label>
          <label class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="teamsize"
              [checked]="teamSize == 2"
              [ngModelOptions]="{ standalone: true }"
              id="teamsize3"
              [value]="2"
              [(ngModel)]="teamSize"
            />
            <span class="form-check-label" for="teamsize3"> 6-10 </span>
          </label>
          <label class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="teamsize"
              id="teamsize4"
              [checked]="teamSize == 3"
              [ngModelOptions]="{ standalone: true }"
              [value]="3"
              [(ngModel)]="teamSize"
            />
            <span class="form-check-label" for="teamsize4"> 11-25 </span>
          </label>
          <label class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="teamsize"
              [checked]="teamSize == 4"
              [ngModelOptions]="{ standalone: true }"
              id="teamsize5"
              [value]="4"
              [(ngModel)]="teamSize"
            />
            <span class="form-check-label" for="teamsize5"> 26+ </span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div
    class="sign-up-testimonial"
    *ngIf="['initial', 'trial'].includes(drawerState)"
  >
    <a
      class="sign-up-capterra"
      target="_blank" rel="noreferrer noopener"
      href="https://www.capterra.com/p/180857/Client-Hub/"
      ><img
        alt="Capterra Best Value for Collaboration Nov-2020"
        title="Best Value Badge"
        src="https://cdn0.capterra-static.com/assets/images/gdm-badges/CAP_CrowdBadge_BestValue_Full-Color.png"
    /></a>
    <div class="testimonial">
      <div class="testimonial-avatar-name">
        <div
          class="testimonial-avatar"
          style="background: url(assets/img/landing-page-v3/blake.png)"
        ></div>
        <div class="testimonial-name">
          Blake Oliver<br />
          <strong>Founder &#64; Earmark App</strong>
        </div>
      </div>
      <div class="testimonial-text">
        When I ran my firm, I dreamed about having an app just like this that
        can solve these exact pain points. Bravo to Client Hub for actually
        doing it.
      </div>
    </div>
  </div>

  <div class="content" *ngIf="drawerState == 'owner'">
    <div *ngIf="teamSize == '0' || _isTrial">
      <h4>You</h4>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="firstname">First name</label>
            <input
              required
              [(ngModel)]="ownerFirstName"
              type="text"
              id="firstname"
              class="form-control"
              placeholder="Your first name"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="lastname">Last name</label>
            <input
              required
              [(ngModel)]="ownerLastName"
              type="text"
              id="lastname"
              class="form-control"
              placeholder="Your last name"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="email">Work email</label>
        <p style="font-size: 1rem; opacity: 0.8">
          Use your work email, because this is where you will get notified of
          key activity
        </p>
        <input
          required
          [(ngModel)]="ownerEmail"
          type="email"
          id="email"
          class="form-control"
          placeholder="Your work email address"
        />
        <p
          style="font-size: 1rem; opacity: 0.8; color: red"
          *ngIf="lastValidatedEmail == ownerEmail && !validEmail"
        >
          {{ emailErrorMessage }}
        </p>
      </div>
      <div class="form-group">
        <label for="phone">Phone</label>
        <input
          [(ngModel)]="ownerPhone"
          type="text"
          id="phone"
          class="form-control"
          placeholder="Phone number"
        />
      </div>
    </div>
  </div>

  <div class="content" *ngIf="drawerState == 'video'">
    <div>
      <h2>
        Here is a 10-minute video demo how Client Hub can deliver value for your
        firm:
      </h2>
      <div
        class="wistia_responsive_padding"
        style="padding: 56.25% 0 0 0; position: relative"
      >
        <div
          class="wistia_responsive_wrapper"
          style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
        >
          <iframe
            src="https://fast.wistia.net/embed/iframe/xqf5qsbc0n?videoFoam=true"
            title="Client Hub Demo Video 2-22"
            allow="autoplay; fullscreen"
            allowtransparency="true"
            frameborder="0"
            scrolling="no"
            class="wistia_embed"
            name="wistia_embed"
            msallowfullscreen
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
      <script
        src="https://fast.wistia.net/assets/external/E-v1.js"
        async
      ></script>
    </div>
  </div>

  <div
    class="content"
    *ngIf="drawerState == 'calendar' && _isTrial"
    style="display: flex"
  >
    <div style="flex-grow: 1; display: flex; flex-direction: column">
      <h2>Complimentary Onboarding</h2>
      <p>
        So that we can help you set up and get the most out of Client Hub,
        please choose a time below for this onboarding meeting to get the most
        out of your Client Hub trial:
      </p>
      <iframe
        style="
          width: 100%;
          flex-grow: 1;
          overflow: auto;
          min-width: 320px;
          height: 630px;
        "
        src="https://calendly.com/d/d38-csw-3w2/client-hub-product-demo-discussion?hide_event_type_details=1&hide_gdpr_banner=1"
        frameborder="0"
      ></iframe>
    </div>
  </div>

  <div
    class="content"
    *ngIf="drawerState == 'calendar' && !_isTrial"
    style="display: flex"
  >
    <div style="flex-grow: 1; display: flex; flex-direction: column">
      <h2>Schedule a demo</h2>
      <p>
        We look forward to learning more about your firm and helping you
        evaluate Client Hub. Please choose a time below:
      </p>
      <iframe
        style="
          width: 100%;
          flex-grow: 1;
          overflow: auto;
          min-width: 320px;
          height: 630px;
        "
        src="https://calendly.com/d/d38-csw-3w2/client-hub-product-demo-discussion?hide_event_type_details=1&hide_gdpr_banner=1"
        frameborder="0"
      ></iframe>
    </div>
  </div>

  <div class="content" *ngIf="drawerState == 'password'">
    <div>
      <h4>Create a password</h4>
      <p>Create a password for your account</p>
      <!-- Password -->
      <div class="password-fields">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="password">Password</label>
              <input
                [(ngModel)]="password"
                type="password"
                id="password"
                class="form-control"
                placeholder="Your password"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="confirmpassword">Confirm Password</label>
              <input
                [(ngModel)]="confirmpassword"
                type="password"
                id="confirmpassword"
                class="form-control"
                placeholder="Confirm your password"
              />
            </div>
          </div>
        </div>
        <p style="font-size: 1rem; opacity: 0.8">Your password must contain:</p>
        <p
          style="font-size: 1rem; opacity: 0.8"
          [ngClass]="{ 'green-text': hasCapitalLetter() }"
        >
          Capital letters
        </p>
        <p
          style="font-size: 1rem; opacity: 0.8"
          [ngClass]="{ 'green-text': hasLowerCaseLetter() }"
        >
          Lower-case letters
        </p>
        <p
          style="font-size: 1rem; opacity: 0.8"
          [ngClass]="{ 'green-text': hasSymbols() }"
        >
          Symbols
        </p>
        <p
          style="font-size: 1rem; opacity: 0.8"
          [ngClass]="{ 'green-text': hasNumbers() }"
        >
          Numbers
        </p>
        <p
          style="font-size: 1rem; opacity: 0.8"
          [ngClass]="{ 'green-text': hasEightChars() }"
        >
          At least 8 characters
        </p>
        <p
          style="font-size: 1rem; opacity: 0.8"
          [ngClass]="{ 'green-text': isConfirmPasswordSame() }"
        >
          Confirm password is equal
        </p>
      </div>

      <!-- Promo code -->
      <!-- <div class="form-group">
      <label for="promo">Promo code <span class="optional">optional</span></label>
      <input type="text" id="promo" class="form-control" placeholder="Promo code">
    </div> -->
    </div>
  </div>

  <div class="footer">
    <p
      style="font-size: 1rem; opacity: 0.8"
      *ngIf="['owner', 'password'].includes(drawerState)"
    >
      By clicking to continue, you agree to our
      <a routerLink="/terms-of-service" target="_blank" rel="noreferrer noopener">Terms of Service</a> and
      <a routerLink="/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a>.
    </p>
    <p *ngIf="drawerState == 'video'" style="font-size: 1rem; opacity: 0.8">
      After watching the video, click Continue below
    </p>
    <button
      class="btn btn-success btn-lg btn-block"
      *ngIf="['initial', 'trial'].includes(drawerState)"
      (click)="startSignUp()"
      [disabled]="teamSize == '' || accountingCompanyName == ''"
    >
      Continue
    </button>
    <button
      class="btn btn-success btn-lg btn-block"
      *ngIf="drawerState == 'owner'"
      (click)="continue()"
      [disabled]="validateForm()"
    >
      Continue
    </button>
    <button
      class="btn btn-success btn-lg btn-block"
      *ngIf="drawerState == 'video' || (drawerState == 'calendar' && _isTrial)"
      (click)="continueToPassword()"
    >
      Continue to start your free trial
    </button>
    <button
      class="btn btn-success btn-lg btn-block"
      *ngIf="drawerState == 'password'"
      (click)="continueToTrial()"
      [disabled]="validatePassword()"
    >
      Start your free trial
    </button>
  </div>
</div>
<!-- 
<div class="backdrop" (click)="closeDrawer()"></div> -->
